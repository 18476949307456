import { FRONT_URL, SEND_EMAIL_URL } from '../const/const';

/**
 * Function to open new tab to upload files
 * @param {Object} param0 that receives apiKey and identtifier
 */
const sendToMobile = async ({
  apiKey,
  identifier,
  method,
  email,
}: {
  apiKey: string;
  identifier: string;
  method: 'sms' | 'email' | 'whatsapp';
  email: string;
}) => {
  const json = {
    apiKey,
    identifier,
    popup: false,
    mobile: true,
    onlyCapture: false,
  };
  const jsonToBase64 = btoa(JSON.stringify(json));

  switch (method) {
    case 'email':
      const response = await fetch(SEND_EMAIL_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
        body: JSON.stringify({
          url: `${FRONT_URL}?state=${jsonToBase64}`,
          email,
        }),
      });

      if (response?.ok) {
        return response.text();
      }
      return { error: response.json() };
    case 'sms':
      return 'not available';
    case 'whatsapp':
      return 'not available';
  }
};

export default sendToMobile;
